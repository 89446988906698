import "./ContentSection.css";
import { Button } from "../button/Button";
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import Carousell from "../carousell/Carousell";
import { virtueObj } from '../../pages/Contribute/Data';

function ContentSection({
    id,
    background,
    topLine,
    lightText,
    lightTextDesc,
    header,
    description,
    description2,
    description3,
    description4,
    description5,
    description6,
    description7,
    img,
    alt,
    imgStart,
    button1,
    button2,
    fullscreen,
    button1link,
    button2link,
    googleform,
    carousel,
    csr
}) {
    return (
        <>
            <div className={fullscreen ? "fullscreen" : ""} id={id}>
                <div
                    className={
                        background
                            ? "content-section blue-background"
                            : "content-section" 
                    }

                    
                >
                    <div className="container">
                        <div
                            className="row content-row"
                            style={{
                                display: "flex",
                                flexDirection:
                                    imgStart === "start"
                                        ? "row-reverse"
                                        : "row",
                            }}
                        >
                            <div className="col">
                                <div className="content-img-wrapper">
                                    {carousel ? (
                                        <Carousell {...virtueObj} />
                                    ) : (
                                        <img
                                            src={img}
                                            alt={alt}
                                            className="content-img"
                                        />
                                    )}
                                </div>
                            </div>

                            <div className="col">
                                <div className="content-text-wrapper">
                                    <div className="top-line">{topLine}</div>
                                    <div className="content-title-wrapper">
                                        <span
                                            // style={{verticalAlign: "middle"}}
                                            className={
                                                lightText
                                                    ? "content-header"
                                                    : "content-header dark"
                                            }
                                        >
                                            {header}
                                        </span>                                        
                                    </div>
                                    
                                    <p
                                        className={
                                            lightTextDesc
                                                ? "content-subtitle"
                                                : "content-subtitle dark"
                                        }
                                    >
                                        {description}
                                    </p>

                                    <p
                                        className={
                                            lightTextDesc
                                                ? "content-subtitle"
                                                : "content-subtitle dark"
                                        }
                                    >
                                        {description2}
                                    </p>

                                    <p
                                        className={
                                            lightTextDesc
                                                ? "content-subtitle"
                                                : "content-subtitle dark"
                                        }
                                    >
                                        {description3}
                                    </p>

                                    <p
                                        className={
                                            lightTextDesc
                                                ? "content-subtitle"
                                                : "content-subtitle dark"
                                        }
                                    >
                                        {description4}
                                    </p>

                                    <p
                                        className={
                                            lightTextDesc
                                                ? "pointer-subtitle"
                                                : "content-subtitle dark"
                                        }
                                    >
                                        {description5}
                                    </p>

                                    <p
                                        className={
                                            lightTextDesc
                                                ? "pointer-subtitle"
                                                : "content-subtitle dark"
                                        }
                                    >
                                        {description6}
                                    </p>

                                    <p
                                        className={
                                            lightTextDesc
                                                ? "pointer-subtitle"
                                                : "content-subtitle dark"
                                        }
                                    >
                                        {description7}
                                    </p>

                                    <div className="buttons-section">
                                        {button2 ? (
                                            <div>
                                                <span>
                                                    <Link to={button2link}>
                                                        <Button
                                                            buttonSize="btn--large"
                                                            buttonStyle="btn--outline"
                                                        >
                                                            {button2}
                                                        </Button>
                                                    </Link>
                                                </span>
                                            </div>
                                        ) : (
                                            ""
                                        )}

                                        {button1 ? (
                                            <div style={{ marginLeft: "4px" }}>
                                                <span>
                                                    {googleform ? (
                                                        <Link
                                                            to={{
                                                                pathname:
                                                                    "https://docs.google.com/forms/d/e/1FAIpQLSdqE8SYaQ6F0Om9r6mQk7jxZ13PyCU5ZNsMhTWYiabVQH1Wqg/viewform",
                                                            }}
                                                            target="_blank"
                                                        >
                                                            <Button
                                                                buttonSize="btn--large"
                                                                buttonStyle="btn--outline"
                                                            >
                                                                {button1}
                                                            </Button>
                                                        </Link>
                                                    ) : (
                                                        <HashLink to={button1link}>
                                                            <Button
                                                                buttonSize="btn--large"
                                                                buttonStyle="btn--outline"
                                                            >
                                                                {button1}
                                                            </Button>
                                                        </HashLink>
                                                    )}
                                                </span>
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ContentSection;
