import React, { useEffect } from 'react';
import ContentSection from '../../components/content/ContentSection';
import { ContactUsObj } from './Data';

function ContactUs() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <title>Contact Us</title>
      <ContentSection {...ContactUsObj} />
    </>
  );
}

export default ContactUs;
