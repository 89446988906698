import React from 'react';
import { Link } from 'react-router-dom';
import './EventSection.css';

function EventSection() {
  return (
    <>
      <div className='home-card-header'>
        Past Events
      </div>
      <div class="events-container" >
        <div class="timeline">
          <ul>
            <li>
            <div class="timeline-content">
                <h3 class="date">Jun 2022</h3>
                <Link to={{ pathname: 'https://www.digitalforlife.gov.sg/' }} target="_blank">
                <h1><img src='images/events/digitalforlife.jpg' /></h1>
                </Link>
                <p>The SmartBFA team spent our Sunday showcasing our wheelchair accessibility project at IMDA's Digital for Life Festival .
Thanks to all that came by our booth and letting us share how we can make Singapore more wheelchair accessible!

We are always looking out for more volunteers to help collect wheelchair accessible path data.</p>
              </div>
            </li>
            <li>
              <div class="timeline-content">
                <h3 class="date">Apr 2022</h3>
                <Link to={{ pathname: 'https://www.facebook.com/photo?fbid=366802795487007&set=a.213150294185592' }} target="_blank">
                <h1><img src='images/events/govsg.jpg' /></h1>
                </Link>
                <p>SmartBFA was featured by Gov.sg: </p>
                <p>"SmartBFA, a mobile app that maps accessibility routes, has been a great help when planning my outings. It notifies me of the potential obstacles along the way, and even the angle of the ramp. I was very excited when I learned about SmartBFA and quickly signed up to be a volunteer." - Apple Yap</p>
                <p>"I believe that in order for us to build an inclusive society, we need to create greater awareness and empathy about those who are different from us. In Singapore, many of us are generally not aware of the needs of persons with disabilities. So, it is not just about one specific group, but all disability groups.”

- Tan Hwee Xian, Project Lead</p>
              </div>
            </li>
            <li>
              <div class="timeline-content">
                <h3 class="date">Nov 2021</h3>
                <Link to={{ pathname: 'https://saot.org.sg/' }} target="_blank">
                <h1><img src='images/events/OT.png' /></h1>
                </Link>
                <p>We had the honour to share SmartBFA with the Singapore Association of Occupational Therapists (SAOT) and fellow occupational therapists! We discussed the usage of Personal Mobility Aids in Singapore, obstacles for PMA users while navigating around Singapore, and explored the use of tech (such as SmartBFA) to ameliorate these issues.</p>
              </div>
            </li>
            <li>
              <div class="timeline-content">
                <h3 class="date">May 2021</h3>
                <Link to={{ pathname: 'https://better.sg/blog/2021/05/24/interview-mercurics-team-smartbfa-mapping-accessibility/' }} target="_blank"><h1><img src='images/events/smartbfa-bettersg.png' /></h1></Link>
                <p>'Mapping Accessibility', SmartBFA is featured in better.sg as a #techforgood project.</p>
              </div>
            </li>
            <li>
              <div class="timeline-content">
                <h3 class="date">Dec 2020</h3>
                <Link to={{ pathname: 'https://www.developer.tech.gov.sg/communities/events/stack-2020' }} target="_blank"><h1><img src='images/events/stack2020.png' /></h1></Link>
                <p>‘Creating a barrier-free Singapore through crowdsourcing – the SmartBFA experience’ will be presented as an on-demand video in the STACK 2020 Developers Conference!</p>
              </div>
            </li>
            <li>
              <div class="timeline-content">
                <h3 class="date">May 2020</h3>
                <Link to={{ pathname: 'https://research.smu.edu.sg/sc01_Strengthening-Social-Fabric-Quality-of-Life' }} target="_blank"><h1><img src='images/events/smuSocialFabric.jpg' /></h1></Link>
                <p>SmartBFA (Finding the Path to an Inclusive Society) is featured in the Research@SMU Booklet: Strengthening Social Fabric & Quality of Life.</p>
              </div>
            </li>
            <li>
              <div class="timeline-content">
                <h3 class="date">March 2020</h3>
                <Link to={{ pathname: 'https://www.channelnewsasia.com/cnainsider/is-technology-helping-the-disabled-with-problems-of-daily-living-772291' }} target="_blank"><h1><img src='images/events/cnaFeature.png' /></h1></Link>
                <p>SmartBFA is featured in ChannelNewsAsia’s Gadg(aid) Episode 1 – Breaking Physical Boundaries. Link to CNA article.</p>
              </div>
            </li>
            <li>
              <div class="timeline-content">
                <h3 class="date">Oct 2019</h3>
                <Link to={{ pathname: 'https://ieeexplore.ieee.org/document/8990857' }} target="_blank"><h1><img src='images/events/IEEE.png' /></h1></Link>
                <p>Paper presentation of ‘ SmartBFA: A Passive Crowdsourcing System for Point-to-Point Barrier-Free Access’ @ IEEE LCN 2019.</p>
              </div>
            </li>
            <li>
              <div class="timeline-content">
                <h3 class="date">April 2019</h3>
                <Link to={{ pathname: 'https://soundcloud.com/sgsmu/creating-an-inclusive-society-through-crowdsourced-barrier-free-access-information' }} target="_blank"><h1><img src='images/events/soundCloud.png' /></h1></Link>
                <p>Podcast on Creating an inclusive society through crowdsourced barrier-free access information.</p>
              </div>
            </li>
            <li>
              <div class="timeline-content" >
                <h3 class="date">April 2019</h3>
                <Link to={{ pathname: 'http://hxtan.info/wp-content/uploads/201902-sdsc-industry-samba.png' }} target="_blank"><h1><img src='images/events/demo.png' /></h1></Link>
                <p>SmartBFA exhibition at SDSC Industry Engagement and Demo Day.</p>
              </div>
            </li>
            <li>
              <div class="timeline-content">
                <h3 class="date">Oct 2018</h3>
                <Link to={{ pathname: 'https://www.straitstimes.com/singapore/app-to-help-those-with-disabilities-get-around' }} target="_blank"><h1><img src='images/events/ST-feature.png' /></h1></Link>
                <p>SmartBFA is featured in The Straits Times!</p>
              </div>
            </li>
            <li>
              <div class="timeline-content">
                <h3 class="date">Oct 2018</h3>
                <h1><img src='images/events/2018-purpleparade-smartbfa.jpg' /></h1>
                <p>SmartBFA participates in #purpleparade 2018, as part of “BCA and Partners” contingent.</p>
              </div>
            </li>
            <li>
              <div class="timeline-content">
                <h3 class="date">Oct 2018</h3>
                <Link to={{ pathname: 'http://hxtan.info/wp-content/uploads/2018-qsconversation.png' }} target="_blank"><h1><img src='images/events/qsBooklet.png' /></h1></Link>
                <p>SmartBFA was featured in the research booklet in QS in Conversation, Singapore.</p>
              </div>
            </li>
            <li>
              <div class="timeline-content">
                <h3 class="date">2018</h3>
                <h1><img src='images/events/women_conference.jpeg' /></h1>
                <p>PAP Women's Wing Conference and Smart Technology Fair.</p>
              </div>
            </li>
            <li>
              <div class="timeline-content">
                <h3 class="date">2018</h3>
                <h1><img src='images/events/campus_party.jpeg' /></h1>
                <p>Campus Party.</p>
              </div>
            </li>
            <li>
              <div class="timeline-content">
                <h3 class="date">2018</h3>
                <h1><img src='images/events/social_icon.jpeg' /></h1>
                <p>Social Icon.</p>
              </div>
            </li>
            <li>
              <div class="timeline-content">
                <h3 class="date">2018</h3>
                <h1><img src='images/events/product_launch.jpeg' /></h1>
                <p>Bukit Timah's Product Launch.</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
export default EventSection;
