import React, { useEffect } from 'react';
import TeamSection from './TeamSection';
import { homeObjThree } from './Data';
import EventSection from './EventSection';
import CardSection from '../../components/content/CardSection';
import Credits from './Credits/Credits';

function AboutUs() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  return (
    <>
      <title>About Us</title>
      <CardSection {...homeObjThree} />
      <TeamSection/>
      <EventSection></EventSection>
      <Credits/>
    </>
  );
}

export default AboutUs;
