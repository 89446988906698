import React from "react";
import { Link } from "react-router-dom";
import "./TeamSection.css";
import { GrLinkedin } from "react-icons/gr";
import { IconContext } from "react-icons/lib";

function TeamSection() {
    return (
        <div className="team-section">
            <div className="team-header">Meet the SmartBFA Team</div>

            <IconContext.Provider value={{ color: "#0072b1", size: 24 }}>
                <div className="team-container">
                    <div className="team-details-header">Main Team</div>
                </div>

                <div
                    className="team-container"
                    style={{ justifycontent: "left" }}
                >
                    <div className="team-details">
                        <Link
                            to={{
                                pathname:
                                    "https://www.linkedin.com/in/kai-reuber/",
                            }}
                            target="_blank"
                        >
                            <div class="hover-image-container">
                                <img
                                    src="./images/team/kai.jpg"
                                    alt="Avatar"
                                    class="hover-image"
                                />
                                <div class="hover-middle">
                                    <GrLinkedin></GrLinkedin>
                                </div>
                            </div>
                        </Link>
                        <p>Kai REUBER</p>
                        <span>Project Lead</span>
                    </div>

                    <div className="team-details">
                        <Link
                            to={{
                                pathname: "https://www.linkedin.com/in/hxtan/",
                            }}
                            target="_blank"
                        >
                            <div class="hover-image-container">
                                <img
                                    src="./images/team/hx.jpeg"
                                    alt="Avatar"
                                    class="hover-image"
                                />
                                <div class="hover-middle">
                                    <GrLinkedin></GrLinkedin>
                                </div>
                            </div>
                        </Link>
                        <p>Hwee Xian TAN</p>
                        <span>Project Lead</span>
                    </div>

                    <div className="team-details">
                        <Link
                            to={{
                                pathname: "https://www.linkedin.com/in/shih-fen-cheng/",
                            }}
                            target="_blank"
                        >
                            <div class="hover-image-container">
                                <img
                                    src="./images/team/sfcheng.jpg"
                                    alt="Avatar"
                                    class="hover-image"
                                />
                                <div class="hover-middle">
                                    <GrLinkedin></GrLinkedin>
                                </div>
                            </div>
                        </Link>
                        <p>Shih-Fen CHENG</p>
                        <span>Project Lead / Academic Advisor</span>
                    </div>

                    <div className="team-details">
                        <Link
                            to={{
                                pathname:
                                    "https://www.linkedin.com/in/brian-yap-678b0b56/",
                            }}
                            target="_blank"
                        >
                            <div class="hover-image-container">
                                <img
                                    src="./images/team/brian.jpg"
                                    alt="Avatar"
                                    class="hover-image"
                                />
                                <div class="hover-middle">
                                    <GrLinkedin></GrLinkedin>
                                </div>
                            </div>
                        </Link>
                        <p>Brian YAP</p>
                        <span>Community Co-ordinator</span>
                    </div>

                    <div className="team-details">
                        <Link
                            to={{
                                pathname:
                                    "https://www.linkedin.com/in/thamray/",
                            }}
                            target="_blank"
                        >
                            <div class="hover-image-container">
                                <img
                                    src="./images/team/ray.jpg"
                                    alt="Avatar"
                                    class="hover-image"
                                />
                                <div class="hover-middle">
                                    <GrLinkedin></GrLinkedin>
                                </div>
                            </div>
                        </Link>
                        <p>Ray THAM</p>
                        <span>Backend Engineer</span>
                    </div>

                    <div className="team-details">
                        <Link
                            to={{
                                pathname:
                                    "https://www.linkedin.com/in/theresagohruisi/",
                            }}
                            target="_blank"
                        >
                            <div class="hover-image-container">
                                <img
                                    src="./images/team/theresa.jpeg"
                                    alt="Avatar"
                                    class="hover-image"
                                />
                                <div class="hover-middle">
                                    <GrLinkedin></GrLinkedin>
                                </div>
                            </div>
                        </Link>
                        <p>Theresa GOH</p>
                        <span>Community Outreach Manager</span>
                    </div>

                    <div className="team-details">
                        <Link
                            to={{
                                pathname:
                                    "https://www.linkedin.com/in/aznikah",
                            }}
                            target="_blank"
                        >
                            <div class="hover-image-container">
                                <img
                                    src="./images/team/AznikahAbdulNizar.jpg"
                                    alt="Avatar"
                                    class="hover-image"
                                />
                                <div class="hover-middle">
                                    <GrLinkedin></GrLinkedin>
                                </div>
                            </div>
                        </Link>
                        <p>Aznikah ABDUL NIZAR</p>
                        <span>Technical Specialist Trainee</span>
                    </div>

                    <div className="team-details">
                        <Link
                            to={{
                                pathname:
                                    "https://www.linkedin.com/in/danush-aaditya",
                            }}
                            target="_blank"
                        >
                            <div class="hover-image-container">
                                <img
                                    src="./images/team/DanushAADITYA.jpg"
                                    alt="Avatar"
                                    class="hover-image"
                                />
                                <div class="hover-middle">
                                    <GrLinkedin></GrLinkedin>
                                </div>
                            </div>
                        </Link>
                        <p>Danush AADITYA</p>
                        <span>Data Scientist</span>
                    </div>
                    <div className="team-details">
                        <Link
                            to={{
                                pathname:
                                    "https://www.linkedin.com/in/chxinyu",
                            }}
                            target="_blank"
                        >
                            <div class="hover-image-container">
                                <img
                                    src="./images/team/xinyu.jpg"
                                    alt="Avatar"
                                    class="hover-image"
                                />
                                <div class="hover-middle">
                                    <GrLinkedin></GrLinkedin>
                                </div>
                            </div>
                        </Link>
                        <p>Xinyu CHEN</p>
                        <span>Data Scientist</span>
                    </div>
                    <div className="team-details">
                        <Link
                            to={{
                                pathname:
                                    "https://www.linkedin.com/in/ker-wei-xiang/",
                            }}
                            target="_blank"
                        >
                            <div class="hover-image-container">
                                <img
                                    src="./images/team/weixiang.jpg"
                                    alt="Avatar"
                                    class="hover-image"
                                />
                                <div class="hover-middle">
                                    <GrLinkedin></GrLinkedin>
                                </div>
                            </div>
                        </Link>
                        <p>Wei Xiang KER</p>
                        <span>Mobile App Developer</span>
                    </div>
                </div>

                <div className="team-container" style={{ marginTop: "20px" }}>
                    <div className="team-details-header">Contributors</div>
                </div>

                <div className="team-container">
                    <div className="team-details">
                        <Link
                            to={{
                                pathname:
                                    "https://www.linkedin.com/in/alisha-saini-028aa1125/",
                            }}
                            target="_blank"
                        >
                            <div class="hover-image-container">
                                <img
                                    src="./images/team/alisha.jpeg"
                                    alt="Avatar"
                                    class="hover-image"
                                />
                                <div class="hover-middle">
                                    <GrLinkedin></GrLinkedin>
                                </div>
                            </div>
                        </Link>
                        <p>Alisha SAINI</p>
                    </div>

                    <div className="team-details">
                        <Link
                            to={{
                                pathname:
                                    "https://www.linkedin.com/in/yap-jit-siang-aloysius-633222192/",
                            }}
                            target="_blank"
                        >
                            <div class="hover-image-container">
                                <img
                                    src="./images/team/aloy.png"
                                    alt="Avatar"
                                    class="hover-image"
                                />
                                <div class="hover-middle">
                                    <GrLinkedin></GrLinkedin>
                                </div>
                            </div>
                        </Link>
                        <p>Aloysius YAP</p>
                    </div>

                    <div className="team-details">
                        <Link
                            to={{
                                pathname:
                                    "https://www.linkedin.com/in/ashleyykq/",
                            }}
                            target="_blank"
                        >
                            <div class="hover-image-container">
                                <img
                                    src="./images/team/ashley.jpg"
                                    alt="Avatar"
                                    class="hover-image"
                                />
                                <div class="hover-middle">
                                    <GrLinkedin></GrLinkedin>
                                </div>
                            </div>
                        </Link>
                        <p>Ashley YEONG</p>
                    </div>

                    <div className="team-details">
                        <Link
                            to={{
                                pathname:
                                    "https://www.linkedin.com/in/leechengkai/",
                            }}
                            target="_blank"
                        >
                            <div class="hover-image-container">
                                <img
                                    src="./images/team/chengkai.jpg"
                                    alt="Avatar"
                                    class="hover-image"
                                />
                                <div class="hover-middle">
                                    <GrLinkedin></GrLinkedin>
                                </div>
                            </div>
                        </Link>
                        <p>Chengkai LEE</p>
                    </div>

                    <div className="team-details">
                        <Link
                            to={{
                                pathname:
                                    "https://www.linkedin.com/in/claire-t-1564121a0/",
                            }}
                            target="_blank"
                        >
                            <div class="hover-image-container">
                                <img
                                    src="./images/team/claire.jpg"
                                    alt="Avatar"
                                    class="hover-image"
                                />
                                <div class="hover-middle">
                                    <GrLinkedin></GrLinkedin>
                                </div>
                            </div>
                        </Link>
                        <p>Claire TAN</p>
                    </div>

                    <div className="team-details">
                        <Link
                            to={{
                                pathname:
                                    "https://www.linkedin.com/in/huiguang-liang/",
                            }}
                            target="_blank"
                        >
                            <div class="hover-image-container">
                                <img
                                    src="./images/team/hg.jpeg"
                                    alt="Avatar"
                                    class="hover-image"
                                />
                                <div class="hover-middle">
                                    <GrLinkedin></GrLinkedin>
                                </div>
                            </div>
                        </Link>
                        <p>Howard LIANG</p>
                    </div>

                    <div className="team-details">
                        <Link
                            to={{
                                pathname:
                                    "https://www.linkedin.com/in/hwee-pink-tan-0122966/",
                            }}
                            target="_blank"
                        >
                            <div class="hover-image-container">
                                <img
                                    src="./images/team/hp.jpeg"
                                    alt="Avatar"
                                    class="hover-image"
                                />
                                <div class="hover-middle">
                                    <GrLinkedin></GrLinkedin>
                                </div>
                            </div>
                        </Link>
                        <p>Hwee Pink TAN</p>
                    </div>

                    <div className="team-details">
                        <Link
                            to={{
                                pathname:
                                    "https://www.linkedin.com/in/job-seow-jian-liang-4065b2172/",
                            }}
                            target="_blank"
                        >
                            <div class="hover-image-container">
                                <img
                                    src="./images/team/job.jpg"
                                    alt="Avatar"
                                    class="hover-image"
                                />
                                <div class="hover-middle">
                                    <GrLinkedin></GrLinkedin>
                                </div>
                            </div>
                        </Link>
                        <p>Job SEOW</p>
                    </div>

                    <div className="team-details">
                        <Link
                            to={{
                                pathname:
                                    "https://www.linkedin.com/in/najulah/",
                            }}
                            target="_blank"
                        >
                            <div class="hover-image-container">
                                <img
                                    src="./images/team/najulah.png"
                                    alt="Avatar"
                                    class="hover-image"
                                />
                                <div class="hover-middle">
                                    <GrLinkedin></GrLinkedin>
                                </div>
                            </div>
                        </Link>
                        <p>MOHADMED Najulah</p>
                    </div>

                    <div className="team-details">
                        <Link
                            to={{
                                pathname:
                                    "https://www.linkedin.com/in/nazir-kamaldin/",
                            }}
                            target="_blank"
                        >
                            <div class="hover-image-container">
                                <img
                                    src="./images/team/nazir.jpeg"
                                    alt="Avatar"
                                    class="hover-image"
                                />
                                <div class="hover-middle">
                                    <GrLinkedin></GrLinkedin>
                                </div>
                            </div>
                        </Link>
                        <p>Nazir KAMALDIN</p>
                    </div>

                    <div className="team-details">
                        <div>
                            <img
                                src="./images/team/susan.png"
                                alt="Avatar"
                                class="hover-image"
                            />
                        </div>
                        <p>Susan KEE</p>
                    </div>

                    <div className="team-details">
                        <Link
                            to={{
                                pathname: "https://www.linkedin.com/in/swkong/",
                            }}
                            target="_blank"
                        >
                            <div class="hover-image-container">
                                <img
                                    src="./images/team/songwei.png"
                                    alt="Avatar"
                                    class="hover-image"
                                />
                                <div class="hover-middle">
                                    <GrLinkedin></GrLinkedin>
                                </div>
                            </div>
                        </Link>
                        <p>Songwei KONG</p>
                    </div>

                    <div className="team-details">
                        <Link
                            to={{
                                pathname:
                                    "https://www.linkedin.com/in/victor-tong-joo-chuan-0345465/",
                            }}
                            target="_blank"
                        >
                            <div class="hover-image-container">
                                <img
                                    src="./images/team/victor.png"
                                    alt="Avatar"
                                    class="hover-image"
                                />
                                <div class="hover-middle">
                                    <GrLinkedin></GrLinkedin>
                                </div>
                            </div>
                        </Link>
                        <p>Victor TONG</p>
                    </div>

                    <div className="team-details">
                        <div>
                            <img
                                src="./images/team/zhenyang.jpg"
                                alt="Avatar"
                                class="hover-image"
                            />
                        </div>
                        <p>Zhenyang LIM</p>
                    </div>
                </div>
            </IconContext.Provider>
        </div>
    );
}
export default TeamSection;
