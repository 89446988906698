import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import "./Navbar.css";
import { FaBars, FaTimes } from "react-icons/fa";
import { IconContext } from "react-icons/lib";

function Navbar() {
    const [click, setClick] = useState(false);
    // const [button, setButton] = useState(true);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    // const showButton = () => {
    //     if (window.innerWidth <= 960) {
    //         setButton(false);
    //     } else {
    //         setButton(true);
    //     }
    // };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <IconContext.Provider value={{ color: "#fff" }}>
                <nav className="navbar">
                    <div className="navbar-container container">
                        <Link
                            to="/"
                            className="navbar-logo"
                            onClick={closeMobileMenu}
                        >
                            <img width='140px' src='../../images/smartbfa-logo.jpg'></img>
                        </Link>
                        <div className="menu-icon" onClick={handleClick}>
                            {click ? (
                                <FaTimes color="#17BEBB" />
                            ) : (
                                <FaBars color="#17BEBB" />
                            )}
                        </div>
                        <ul className={click ? "nav-menu active" : "nav-menu"}>
                            <li className="nav-item">
                                <Link
                                    to="/"
                                    className="nav-links"
                                    onClick={closeMobileMenu}
                                >
                                    Home
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link
                                    to="/aboutUs"
                                    className="nav-links"
                                    onClick={closeMobileMenu}
                                >
                                    About Us
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link
                                    to="/solutions"
                                    className="nav-links"
                                    onClick={closeMobileMenu}
                                >
                                    Solutions
                                </Link>
                            </li>
                            <li className="dropdown">
                                <Link
                                    to="/contribute"
                                    className="nav-links"
                                    onClick={closeMobileMenu}
                                >
                                    Contribute
                                </Link>
                                <div class="dropdown-content">
                                    <HashLink
                                        to="contribute#donate"
                                    >
                                        Donate
                                    </HashLink>
                                    <HashLink
                                        to="contribute#dataCollectorVolunteer"
                                    >
                                        Volunteer
                                    </HashLink>
                                    <HashLink
                                        to="contribute#csr"
                                    >
                                        CSR
                                    </HashLink>
                                </div>
                            </li>
                            <li className="nav-item">
                                <Link
                                    to="/contactUs"
                                    className="nav-links"
                                    onClick={closeMobileMenu}
                                >
                                    Contact Us
                                </Link>
                            </li>
                        </ul>
                    </div>
                </nav>
            </IconContext.Provider>
        </>
    );
}

export default Navbar;
