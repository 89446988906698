import React from "react";
import "./VolunteerBoard.css";

function VolunteerBoard() {
    return (
        <div className="volunteer-section">
            <div className="volunteer-header">
                The SmartBFA Wall of Gratitude
            </div>
            <div className="volunteer-subtitles">
                SmartBFA would not be where we are today without the effort and
                passion of our volunteers. We are grateful for your
                contributions.
            </div>

            <div className="volunteer-container">
                <div className="volunteer-details">
                    <div class="hover-image-container">
                        <img
                            src="./images/volunteer/benjamin.png"
                            alt="Avatar"
                            class="hover-image"
                        />
                        <div class="hover-middle">Benjamin Chia</div>
                    </div>
                </div>

                <div className="volunteer-details">
                    <div class="hover-image-container">
                        <img
                            src="./images/volunteer/boonkeng.png"
                            alt="Avatar"
                            class="hover-image"
                        />
                        <div class="hover-middle">Oh Boonkeng</div>
                    </div>
                </div>

                <div className="volunteer-details">
                    <div class="hover-image-container">
                        <img
                            src="./images/volunteer/judy.png"
                            alt="Avatar"
                            class="hover-image"
                        />
                        <div class="hover-middle">Judy Wee</div>
                    </div>
                </div>

                <div className="volunteer-details">
                    <div class="hover-image-container">
                        <img
                            src="./images/volunteer/meldip.png"
                            alt="Avatar"
                            class="hover-image"
                        />
                        <div class="hover-middle">Meldip Kaur</div>
                    </div>
                </div>

                <div className="volunteer-details">
                    <div class="hover-image-container">
                        <img
                            src="./images/volunteer/volunteer.png"
                            alt="Avatar"
                            class="hover-image"
                        />
                        <div class="hover-middle">Khoh Rong Lun</div>
                    </div>
                </div>

                <div className="volunteer-details">
                    <div class="hover-image-container">
                        <img
                            src="./images/volunteer/SuhartiHussain.jpg"
                            alt="Avatar"
                            class="hover-image"
                        />
                        <div class="hover-middle">Suharti Hussain</div>
                    </div>
                </div>

                <div className="volunteer-details">
                    <div class="hover-image-container">
                        <img
                            src="./images/volunteer/GeorgeKanapathy.jpg"
                            alt="Avatar"
                            class="hover-image"
                        />
                        <div class="hover-middle">George Kanapathy</div>
                    </div>
                </div>

                <div className="volunteer-details">
                    <div class="hover-image-container">
                        <img
                            src="./images/volunteer/volunteer.png"
                            alt="Avatar"
                            class="hover-image"
                        />
                        <div class="hover-middle">Aisha Shaik</div>
                    </div>
                </div>

                <div className="volunteer-details">
                    <div class="hover-image-container">
                        <img
                            src="./images/volunteer/volunteer.png"
                            alt="Avatar"
                            class="hover-image"
                        />
                        <div class="hover-middle">Chan Chi Teng</div>
                    </div>
                </div>

                <div className="volunteer-details">
                    <div class="hover-image-container">
                        <img
                            src="./images/volunteer/LinusTeo.jpg"
                            alt="Avatar"
                            class="hover-image"
                        />
                        <div class="hover-middle">Linus Teo</div>
                    </div>
                </div>

                <div className="volunteer-details">
                    <div class="hover-image-container">
                        <img
                            src="./images/volunteer/EdNeo.jpg"
                            alt="Avatar"
                            class="hover-image"
                        />
                        <div class="hover-middle">Ed Neo</div>
                    </div>
                </div>

                <div className="volunteer-details">
                    <div class="hover-image-container">
                        <img
                            src="./images/volunteer/DesmondWan.jpg"
                            alt="Avatar"
                            class="hover-image"
                        />
                        <div class="hover-middle">Desmond Wan</div>
                    </div>
                </div>

                <div className="volunteer-details">
                    <div class="hover-image-container">
                        <img
                            src="./images/volunteer/LawWeining.jpg"
                            alt="Avatar"
                            class="hover-image"
                        />
                        <div class="hover-middle">Law Weining</div>
                    </div>
                </div>

                <div className="volunteer-details">
                    <div class="hover-image-container">
                        <img
                            src="./images/volunteer/volunteer.png"
                            alt="Avatar"
                            class="hover-image"
                        />
                        <div class="hover-middle">Gabriel Kwek Wu Xin</div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default VolunteerBoard;
