import React, { useEffect } from "react";
import {
    DonateObj,
    DataCollectorObj,
    VolunteerObj,
    whyDonateObj,
    csrObj,
} from "./Data";
import ContentSection from "../../components/content/ContentSection";
import { DonatePage } from "./Donate/DonatePage";
import VolunteerBoard from "./Volunteer/VolunteerBoard";
import DonationBoard from "./Donate/DonationBoard";
import CorporatePartners from "./CSR/CorporatePartners";

function Contribute(props) {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <title>SmartBFA</title>
            <ContentSection {...DonateObj} />
            <DonatePage {...whyDonateObj}></DonatePage>
            <DonationBoard />
            <ContentSection {...DataCollectorObj} />
            <ContentSection {...VolunteerObj} />
            <VolunteerBoard />
            <ContentSection {...csrObj}></ContentSection>
            <CorporatePartners></CorporatePartners>
        </>
    );
}

export default Contribute;
