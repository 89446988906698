import React from 'react';
import './HomePage.css';
import { IconContext } from 'react-icons/lib';
import { FaWheelchair , FaDatabase } from 'react-icons/fa';
import { IoIosTimer } from 'react-icons/io';
import { ImRoad} from 'react-icons/im';

function HomePage({
  header,
  headline1,
  headline2,
  description1,
  description2,
  description3,
  description4,
  img,
  img2,
  alt
  }) {
  return (
    <div>
      <div className='home-card-section'>
          {header 
          ? <div className='home-card-header'>
              {header}
            </div>
          : ""
          }

          <div className='home-card-container'>
            <div className='home-card'>
              <p>{headline1}</p>
              <h1><img src={img} alt={alt}/></h1>

              <span >{description1}</span>
              <span style={{fontWeight: "600" , textAlign: 'right' }}>{description2}</span>
            </div>

            <div className='home-card'>
              <p>{headline2}</p>
              <h1><img src={img2} alt={alt}/></h1>
              <span>{description3}</span>
              <span style={{fontWeight: "600" , textAlign: 'right' }}>{description4}</span>
            </div>
          </div>

        <div className='home-data-section home-data-container'>
          <IconContext.Provider value={{ color: '#696969', size: 64 , paddingBottom: 0 }}>
            <div className='home-data-details'>
              <div className='home-data-words-details'>
                <FaWheelchair color='#696969'/>              
              </div>
              <div className='home-data-words-details'>
                <p>159</p>
                <span>Unique volunteers</span>                
              </div>
            </div>

            <div className='home-data-details'>
              <div className='home-data-words-details'>
                <IoIosTimer color='#696969'/>             
              </div>
              <div className='home-data-words-details'>
                <p>38,077</p>
                <span>Hours of data</span>          
              </div>
            </div>

            <div className='home-data-details'>
              <div className='home-data-words-details'>
                <ImRoad color='#696969'/>                
              </div>
              <div className='home-data-words-details'>
                <p>49,594</p>
                <span>Kilometers covered</span>
              </div>
            </div>

            <div className='home-data-details bottom-details'>
              <div className='home-data-words-details'>
                <FaDatabase color='#696969'/>
              </div>
              <div className='home-data-words-details'>
                <p>1.70</p>
                <span>Terabytes of data processed</span>
              </div>
            </div>
          </IconContext.Provider>  
        </div>
      </div>
      
      <div className='home-card-section' style={{backgroundColor: '#c2f0ef'}}>
        <div className='home-card-header'>
          Videos
        </div>

        <div className='home-card-container' >
          <div className='iframe-container'>
            <iframe src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Ftechinasia%2Fvideos%2F1227380290759238%2F&width=500&show_text=false&appId=732988190374294&height=280" width="560" height="315" scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media" allowFullScreen="true"></iframe>
          </div>

          <div className='iframe-container bottom-details-bg-colour'>
            <iframe class="embed-responsive-item" width="560" height="315" src="https://www.youtube.com/embed/QivzCvB4yig" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
          </div>
        </div>
      </div>      
    </div>


  );
}
export default HomePage;
