import "./DonatePage.css";
import { Link } from "react-router-dom";
import { Button } from "../../../components/button/Button";

export const DonatePage = ({ topBannerHeader }) => {
    return (
        <>
            <div className="donate-middle-section">
                <div className="donate_top_banner_wrapper donate_header-wrapper">
                    <div className="top-banner-header">{topBannerHeader}</div>
                    <div className="endheader_donate_wrapper">
                        <Link target="_blank" to={{pathname: 'https://www.giving.sg/disabled-people-s-association/smartbfa'}}>
                            <Button
                                buttonStyle="btn--outline"
                                buttonSize="btn--wide"
                            >
                                {" "}
                                Click to donate on giving.sg{" "}
                            </Button>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    );
};
