import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './App.css';
import Navbar from './components/navbar/Navbar';
import home from './pages/Home/Home';
import aboutUs from './pages/AboutUs/AboutUs';
import solutions from './pages/Solutions/Solutions';
import navigation from './pages/Solutions/Solutions/Navigation';
import dataDashboard from './pages/Solutions/Solutions/DataDashboard';
import dataCollection from './pages/Solutions/Solutions/DataCollection';
import contribute from './pages/Contribute/Contribute';
import virtue from './pages/Contribute/CSR/Virtue';
import contactUs from './pages/ContactUs/ContactUs';
import Footer from './components/footer/Footer';

function App() {
  return (
    <div id="page-container">
        <Router>
            <Navbar />
            <Switch>
            <Route path='/' exact component={home} id="content-wrap"/>
            <Route path='/aboutUs' component={aboutUs} id="content-wrap"/>
            <Route path='/solutions' component={solutions} id="content-wrap"/>
            <Route path='/navigation' component={navigation} id="content-wrap"/>
            <Route path='/dataDashboard' component={dataDashboard} id="content-wrap"/>
            <Route path='/dataCollection' component={dataCollection} id="content-wrap"/>
            <Route path='/contribute' component={contribute} id="content-wrap"/>
            <Route path='/virtue' component={virtue} id="content-wrap"/>
            <Route path='/contactUs' component={contactUs} id="content-wrap"/>
            </Switch>
            <Footer/>
        </Router>
    </div>

  );
}

export default App;


