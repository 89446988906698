import { BiDonateHeart } from 'react-icons/bi';
import { FaWheelchair , FaHandsHelping } from 'react-icons/fa';

export const landingObj = {
  background: false,
  fullscreen: true,
  lightText: false,
  lightTextDesc: false,
  topLine: '',
  header: 'Join us in enabling barrier-free access for Singapore!',
  description: 'SmartBFA provides optimized navigation for wheelchair users. #techforgood #inclusive',
  button1: 'Volunteer Now',   
  button2: 'About Us', 
  button2link: '/aboutUs',
  button1link: '/contribute#dataCollectorVolunteer',  
  imgStart: '',
  img: 'images/illustration/homepage-wheelchair-image.svg',
  alt: 'volunteer'
};

export const callToActionObj = {
  topBannerHeader: 'Contribution Opportunities',
  topBannerSubtitles: 'There are unlimited opportunities for you to contribute and be a part of the SmartBFA team!',
  topBanner: true,
  headline1: <FaWheelchair />,
  headline2: <FaHandsHelping />,
  headline3: <BiDonateHeart />,
  description1: 'Join us in our movement to collect accessibility data from all around Singapore, using just your smartphones.',
  description2: 'Or do you have a particular skillset that you want to offer to SmartBFA?',
  description3: 'Donate towards SmartBFA’s mapping efforts through our non-profit partner and get tax deductions!',
  button1: 'Volunteer as Data Collector',   
  button2: 'Volunteer in Other Areas',
  button3: 'Donate Today',
  button1link: '/contribute#dataCollectorVolunteer',
  button2link: '/contribute#otherSkillsVolunteer',
  button3link: '/contribute#donate'
};

export const testiObj = {
  header: "Testimonials From Our Wheelchair Volunteers",
  topBannerHeader: '',
  topBannerSubtitles: '',
  topBanner: false,
  headline1: "Qian Yin YAP",
  headline2: "George K",
  headline3: '',
  description1: '“Going from one place to another which takes non-wheelchair user 5 minutes could take me ~20 mins to reach the destination.”',
  description2: '“With SmartBFA, I will not have to make multiple de-tours from inaccessible routes, making my social meet-ups much more convenient!”',
  description3: '“Knowing the route is good but knowing exactly where the hurdles and barriers are is the best!”',
  description4: '“With SmartBFA I can travel safe and fast and my loved ones will have a peace of mind always.”',
  img: 'images/volunteer/testimony.png',
  img2: 'images/volunteer/testimony2.png',
};