export const homeObjThree = {
  header: false,
  // header: 'SmartBFA aims to provide barrier-free access (BFA) routes in Singapore through crowdsourced sensor data',
  header: 'Barrier-free access with crowdsourced data',
  headline1: 'Provide barrier free routes',
  headline2: 'Identify areas for improvement',
  headline3: 'Empower people with mobility needs',
  description1: 'With SmartBFA’s navigation tool, wheelchair users can easily find barrier-free access paths as they go about their daily commute, effectively reducing travel distance and time.',
  description2: 'SmartBFA identifies areas that are lacking in accessibility features (such as ramps and lifts) through obstacles that are reported by our volunteers. This data will be provided to town planners for their follow up actions.',
  description3: 'People with mobility troubles often avoid leaving their homes due to a lack of confidence in navigating to their destination easily. SmartBFA empowers these individuals, encouraging them to commute with ease and confidence.',
};
