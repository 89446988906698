import React, { useEffect } from "react";
import SolutionsContentSection from "../../../components/content/SolutionsContentSection";
import { dataCollectionObj } from "../Data";

function DataCollection() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <title>SmartBFA</title>
            <SolutionsContentSection {...dataCollectionObj}></SolutionsContentSection>
        </>
    );
}

export default DataCollection;
