import React, { useEffect } from "react";
import SolutionsContentSection from "../../../components/content/SolutionsContentSection";
import { dataDashboardObj } from "../Data";

function DataDashboard() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <title>SmartBFA</title>
            <SolutionsContentSection {...dataDashboardObj}></SolutionsContentSection>
        </>
    );
}

export default DataDashboard;
