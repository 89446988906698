import React , {useState} from 'react';
import './CardSection.css';
import { IconContext } from 'react-icons/lib';
import { Link } from 'react-router-dom';
import { Button } from '../../components/button/Button';
import { HashLink } from 'react-router-hash-link';


function CardSection({
  topBannerHeader,
  topBannerSubtitles,
  topBanner,

  subheader1,
  subheader2,
  subheader3,

  header,
  header2,
  header2sub,

  headline1,
  headline2,
  headline3,
  description1,
  description2,
  description3,
  button1,
  button2,
  button3,  
  button1link,
  button2link,
  button3link,

  endheader1,
  endheader2,
  endheader3
  }) {

    const [dataVolunteer , setDataVolunteer] = useState(true);

  return (
    <div className='card-section'>
      <IconContext.Provider value={{ color: '#696969', size: 64 }}>
        {topBanner
        ? <div className='top_banner_wrapper header-wrapper'>
            <div className="top-banner-header">
              {topBannerHeader}
            </div>
            <div className='top-banner-subtitles'>
              {topBannerSubtitles}
            </div>
          </div>
        : ""
        }

        {header2 
        ? <div className='card-header2 font_italic'>
            {header2}
          </div>
        : ""
        }

        {header2sub 
        ? <div className='subheader_wrapper font_italic'>
            {header2sub}
          </div>
        : ""
        }

        {header 
        ? <div className='card-header'>
            {header}
          </div>
        : ""
        }

        {subheader2
        ? <div className='subheader_wrapper'>
            <div className='donate_subheaders'>{subheader2}</div>
          </div>
        : ""
        }

        <div className='card-container'>
          <div className='card'>
            <p>{headline1}</p>
            <h1>{description1}</h1>
            
            {button1
            ? <HashLink to={button1link}>
                  <Button buttonSize='btn--medium' >
                    {button1}
                  </Button>
                </HashLink>                    
            : ""
            }            
          </div>

          <div className='card'>
              <p>{headline2}</p>
              <h1>{description2}</h1>

              {button2
              ? <HashLink to={button2link}>
                  <Button buttonSize='btn--medium' >
                    {button2}
                  </Button>
                </HashLink>                      
              : ""
              }
          </div>

          <div className='card'>
              <p>{headline3}</p>
              <h1>{description3}</h1>

              {button3
              ? <HashLink to={button3link}>
                  <Button buttonSize='btn--medium' >
                    {button3}
                  </Button>
                </HashLink>                        
              : ""
              }
          </div>
        </div>
      </IconContext.Provider>    

      {endheader1
        ? <div className='endheader_wrapper'>
            <p className='donate_subheaders'>{endheader1} {subheader3}</p>
            <p className='donate_subheaders'></p>
          </div>
        : ""
        }
    </div>

  );
}
export default CardSection;
