import React, { useEffect } from 'react';
import { virtueObj } from '../Data';
import ContentSection from '../../../components/content/ContentSection';
import CorporatePartners from './CorporatePartners';

function Virtue() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <title>SmartBFA</title>
      <ContentSection {...virtueObj}></ContentSection>
      <CorporatePartners></CorporatePartners>
    </>
  );
}

export default Virtue;
