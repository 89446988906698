import React from 'react';
import { Link } from 'react-router-dom';
import './CorporatePartners.css';

function CorporatePartners() {
  return (
    <div>
      <div className='corporate-section'>
        <div className='corporate-header'>
          Corporate Partners
        </div>
        <div className='corporate-subtitles italic'>
          Thank you for contributing towards building an #inclusive Singapore with SmartBFA! 
        </div>

        <div className='corporate-container'>
          <div className='corporate-details'>
            <Link to={{ pathname: '/virtue'}}>  
                <img className='corporate-img' src='./images/csr/virtue.png' />
            </Link>
          </div>

          <div className='corporate-details'>
            <img className='corporate-img' src='./images/volunteer/volunteer.png' />
          </div>

          <div className='corporate-details'>
            <img className='corporate-img' src='./images/volunteer/volunteer.png' />
          </div>

          <div className='corporate-details'>
            <img className='corporate-img' src='./images/volunteer/volunteer.png' />
          </div>

          <div className='corporate-details'>
            <img className='corporate-img' src='./images/volunteer/volunteer.png' />
          </div>

          <div className='corporate-details'>
            <img className='corporate-img' src='./images/volunteer/volunteer.png' />
          </div>

          <div className='corporate-details'>
            <img className='corporate-img' src='./images/volunteer/volunteer.png' />
          </div>

          <div className='corporate-details'>
            <img className='corporate-img' src='./images/volunteer/volunteer.png' />
          </div>
        </div>
      </div>      
    </div>
  );
}
export default CorporatePartners;