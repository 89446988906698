export const landingObj = {
    fullscreen: true,
    header: "Solutions",
    description:
        "SmartBFA builds and deploys several solutions to tackle different aspects of the same goal - improved navigation for wheelchair users.",
    productName: "Navigation App",
    productDescription:
        "For wheelchair users, caretakers, personal mobility aid users",
    productLink: "Explore Navigation",
    productDescriptionLong:
        "SmartBFA's map navigation app for wheelchair users. Enjoy barrier-free navigation, powered with crowdsourced data.",

    productName2: "Data Dashboard",
    product2Description:
        "For infrastructure authorities, other social enterprises who require data, building owners",
    product2Link: "Explore Data Dashboard",
    product2DescriptionLong:
        "A visual interface with SmartBFA's crowdsourced path accessibility and obstacle data. Identify areas for improvement in Singapore's infrastructure.",

    productName3: "Advanced Data Collection App",
    product3Description:
        "For CSR volunteer partners, SmartBFA Data Collection Volunteers",
    product3Link: "Explore Advanced Data Collection app",
    product3DescriptionLong:
        "This app uses your mobile device's sensors (i.e., gyroscope, accelerometer, etc.) to collect path accessibility data (i.e., path bumpiness, inclination, etc.) Obstacles may also be reported and annotated.",
};

export const navigationObj = {
    fullscreen: true,
    imgStart: "start",
    productName: "Navigation App",
    productDescription: "Improved navigation for wheelchair users.",
    productLink:
        "Our first product, SmartBFA Navigation is a navigation tool that uses crowdsourced path accessibility and obstacle data to recommend faster, easier routes for wheelchair users.",

    feature1:
        "Plan your route from point-to-point, staying informed of obstacles and poor path conditions",
    feature2:
        "Report obstacles you encounter along the way to contribute to the SmartBFA database",
    feature3:
        "Evaluate landmarks' accessibility features, and plan activities accordingly",

    img1: "images/ss/navigationApp1.png",
    img2: "images/ss/navigationApp2.png",
    img3: "images/ss/navigationApp3.png",

    productType: "navigationObj",
};

export const dataDashboardObj = {
    fullscreen: true,
    imgStart: "start",
    productName: "Data Dashboard",
    productDescription:
        "Singapore's database for obstacles, accessibility points, and path accessibility data.",
    productLink:
        "The Data Dashboard is the interface to SmartBFA's database. Evaluate path accessibility data such as ground bumpiness and inclination, obstacle reports, and landmark accessibility features. ",

    feature1: "Heatmap to track data coverage, path accessibility",
    feature2: "Visualise obstacle data, photos, annotations",
    feature3:
        "Evaluate landmark accessibility features for infrastructural work",

    img1: "images/dashboard/dashboard1.png",
    img2: "images/dashboard/dashboard2.png",
    img3: "images/dashboard/dashboard3.png",

    productType: "dataDashboardObj",
};

export const dataCollectionObj = {
    fullscreen: true,
    imgStart: "start",
    productName: "Data Collection",
    productDescription:
        "The only tool you need to collect accessibility data for SmartBFA - with just your phone.",
    productLink:
        "SmartBFA's Advanced Data Collection App (ADC) allows users to collect path accessibility and obstacle data and directly contribute to the SmartBFA database. This app is the main tool used by SmartBFA's Data Collection Volunteers to help map Singapore.",

    feature1:
        "Collect important path accessibility data like path bumpiness and slope incline with your phone's sensors",
    feature2:
        "Use your phone's GPS to help our algorithm identify wheelchair-accessible shortcuts",
    feature3: "Report obstacles and barriers with pictures and annotations",

    img1: "images/adca/adca1.png",
    img2: "images/adca/adca2.png",
    img3: "",

    appstoreLink:
        "https://apps.apple.com/us/app/adc/id1585853442?itsct=apps_box_badge&amp;itscg=30200",
    playstoreLink:
        "https://play.google.com/store/apps/details?id=com.flutter.adc.prod&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1",

    productType: "dataCollectionObj",
};
