import React, { useEffect } from "react";
import SolutionsContentSection from "../../../components/content/SolutionsContentSection";
import { navigationObj } from "../Data";

function Navigation() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <title>SmartBFA</title>
            <SolutionsContentSection {...navigationObj}></SolutionsContentSection>
        </>
    );
}

export default Navigation;
