import React from 'react';
import { Link } from 'react-router-dom';
import './Credits.css';

function Credits() {
  return (

    //recode , so funded by and supported by is side by side, flex
    // our partner is 1 section on its own
    
    //credit-section is currently just padding
    <div className='credit-section'>
      <div className='credit-container'>
        <div className='credit-details'>
        </div>        
        
        <div className='top-credit-details'>
          <div>
            <p>Funded By</p>
            <Link to={{ pathname: 'https://www.toteboard.gov.sg/'}} target="_blank"><img className="credit-img" src="images/sponsors/toteboard.png" alt="Singapore Toteboard"/></Link> 
          </div>

          <div>
            <p>Supported By</p> 
            <Link to={{ pathname: 'https://www.sgenable.sg/Pages/Home.aspx'}} target="_blank"><img className="credit-img" src="images/sponsors/sgenable.png" alt="SG Enable"/></Link> 
          </div>
        </div>
      </div>   

      <div className='credit-container' style={{paddingTop: '30px'}}>
        <div className='credit-details'>
        <div>
          <p>Our Partners</p>
        </div>
        <div>
          <Link to={{ pathname: 'https://www.awwa.org.sg/'}} target="_blank"><img className="credit-img" src="images/sponsors/AWWA.png" alt="AWWA"/></Link>
          <Link to={{ pathname: 'https://www.dpa.org.sg/'}} target="_blank"><img className="credit-img" src="images/sponsors/DPA.jpg" alt="DPA"/></Link>
          <Link to={{ pathname: 'https://www.hbptc.org.sg/'}} target="_blank"><img className="credit-img" src="images/sponsors/hbptc.png" alt="Holland Bukit Panjang Town Council"/></Link>
          <Link to={{ pathname: 'https://www.sata.com.sg/'}} target="_blank"><img className="credit-img" src="images/sponsors/SATA-CommHealth-Logo.png" alt="SATA"/></Link>
          <Link to={{ pathname: 'https://sdsc.sg/'}} target="_blank"><img className="credit-img" src="images/sponsors/SDSC.jpg" alt="Singapore Data Science Consortium"/></Link>
          <Link to={{ pathname: 'https://www.yzehealthrehab.com/'}} target="_blank"><img className="credit-img" src="images/sponsors/yze.logo.png" alt="WIM"/></Link> 
        </div>
        </div>       
      </div>   
    </div>

  );
}
export default Credits;




