// import ReactBootstrapCarousel from "react-bootstrap-carousel";
import "bootstrap/dist/css/bootstrap.css";
import "react-bootstrap-carousel/dist/react-bootstrap-carousel.css";
import Carousel from "react-bootstrap/Carousel";
import "./Carousell.css";

export default function Carousell({ img1, img2, img3, img4 }) {
    return (
        <Carousel variant="dark">
            <Carousel.Item>
                <img className="d-block w-100" src={img1} alt="First slide" />
            </Carousel.Item>
            <Carousel.Item>
                <img className="d-block w-100" src={img2} alt="Second slide" />
            </Carousel.Item>
            {img3 ? (
                <Carousel.Item>
                    <img className="d-block w-100" src={img3} alt="Third slide" />
                </Carousel.Item>
            ) : (
                ""
            )}
            {img4 ? (
                <Carousel.Item>
                    <img className="d-block w-100" src={img4} alt="Third slide" />
                </Carousel.Item>
            ) : (
                ""
            )}
        </Carousel>
    );
}
