export const DonateObj = {
    fullscreen: true,
    background: false,
    lightText: false,
    lightTextDesc: false,
    topLine: '',
    header: 'Help us make a difference.',
    description: 'Every donation goes towards building and optimising our app so that wheelchair users can get to their destination efficiently.',
    description2:"SmartBFA is a project by Mercurics, a social enterprise. Donations can be made through our partner organisation, Disabled People’s Association (DPA). DPA is a non-profit organization that represents the disability community and works to build a barrier-free society in which persons with disabilities can participate in all aspects of life from education to employment and access to social integration.",
    imgStart: '',
    img: 'images/illustration/charitybox.png',
    alt: 'volunteer',
    id: 'donate'
};

export const whyDonateObj = {
    topBannerHeader: 'Make a Donation'
  };
  

export const DataCollectorObj = {
    background: false,
    lightText: false,
    lightTextDesc: false,
    topLine: '',
    header: 'Data Collector',
    description: "As a Data Collector,  you will be helping us to evaluate path accessibility and identify obstacles (e.g., steep slopes and uneven surfaces) in different parts of Singapore.",
    description2: "We have a mobile application that you can install on your phone to make data collection easy and seamless! The data that you collect will go towards identifying barrier-free paths for wheelchair users in Singapore to travel on.",
    description3: "Able-bodied users and wheelchair users are both welcome to volunteer!",
    //description4: "Able-bodied users and wheelchair users are welcome to volunteer!",
    button1: 'Volunteer Now',   
    googleform: true,
    button2: false,   
    imgStart: 'start',
    img: 'images/ss/app_screenshot.png',
    alt: 'smartBFA-app',
    id: 'dataCollectorVolunteer'
  };
  
  export const VolunteerObj = {
    background: true,
    lightText: false,
    lightTextDesc: false,
    topLine: '',
    header: 'Skill-Based Volunteer',
    description:'Do you have a specific set of skills or expertise that can benefit SmartBFA? If you have experience in Social Media Management, Marketing, Community Engagement, Software Development, or any other relevant fields, we’d love to have you come on board as our skill-based volunteer!',
    description2: 'We are always looking for volunteers who share in our passion to build an #inclusive society! Reach out to our friendly team for a chat!',
    button1: 'Volunteer Now',   
    googleform: true,
    button2: false,   
    imgStart: '',
    img: 'images/illustration/volunteer.jpg',
    alt: 'volunteer',
    id: 'otherSkillsVolunteer'
  };

  export const csrObj = {
    background: true,
    lightText: false,
    lightTextDesc: false,
    topLine: '',
    header: 'Corporate Social Responsibility (CSR)',
    description: "SmartBFA believes that every individual has the skills and expertise to help the disadvantaged.",
    description2: "A company who takes socially responsible actions is likely to have employees who have a boosted morale, and also enjoy an elevated level of respect and reputation within the community.",
    description3: "Here's how your company can get involved in supporting our cause: ",
    description4: "- Volunteer staff for data collection efforts",
    description5: "- Donate through our giving.sg page ",
    description6: "- Organise fundraising events",
    description7: "Want to collaborate with SmartBFA? Reach out!",
    button1: 'Contact Us',   
   // button2: 'Contact Us', 
    button1link: '/contactUs',
    //button1link: '/contribute',  
    imgStart: '',
    img: 'images/illustration/csr_grp_photo.png',
    alt: 'csr_group_photo',
    fullscreen: '',
    carousel: false,
    csr: true,
    id: 'csr'
};

export const  virtueObj = {
  background: false,
  fullscreen: true,
  lightText: false,
  lightTextDesc: false,
  topLine: '',
  header: 'Virtue PM',
  description: "Virtue Partners Management prioritises two things: helping their clients achieve their goals, and building a better future. Expanding on their tradition of supporting communities, Virtue PM has now broadened their scope towards a more #Inclusive society, by first building an accessible Singapore for all. ",
  description2: "This year, Virtue Partners Management has contributed $5,000 to sponsor SmartBFA's efforts in collecting crowdsourced path accessibility and obstacle data in Singapore. Proceeds from this donation will go directly to funding more than 400 hours of accessibility data collection. ",
  description3: "Thank you, Virtue Partners Management!",
  description4: "Want to support SmartBFA? Donate with giving.sg or reach out!",
  button2: 'Donate',   
  button1: 'Contact Us', 
  button1link: '/contactUs',
  button2link: '/contribute',  
  imgStart: 'start',
  alt: 'csr_group_photo',
  carousel: true,
  img1: "images/csr/virtueLongLogo.png",
  img2: "images/csr/virtue_wchair.jpg",
  img3: "images/csr/Shaye_and_Theresa.jpg",
  img4: "images/csr/virtue_group_shot.jpg",
  id: 'virtuePM'
};