import React from "react";
import "./SolutionsContentSection.css";
import Carousell from "../carousell/Carousell";
import {
    navigationObj,
    dataCollectionObj,
    dataDashboardObj,
} from "../../pages/Solutions/Data";
import { virtueObj } from "../../pages/Contribute/Data";

function SolutionsContentSection({
    imgStart,
    fullscreen,
    productName,
    productDescription,
    productLink,
    feature1,
    feature2,
    feature3,
    feature4,
    productType,
    appstoreLink,
    playstoreLink,
}) {
    return (
        <>
            <div className={fullscreen ? "fullscreen" : ""}>
                <div className="solution-content-section">
                    <div className="container-sol">
                        <div className="solution-title-wrapper">
                            <span className="content-header dark">
                                {productName}
                            </span>
                        </div>
                        <div
                            className="row content-row"
                            style={{
                                display: "flex",
                                flexDirection:
                                    imgStart === "start"
                                        ? "row-reverse"
                                        : "row",
                            }}
                        >
                            <div className="col">
                                <div className="content-img-wrapper">
                                    {productType === "dataDashboardObj" ? (
                                        <Carousell {...dataDashboardObj} />
                                    ) : productType === "dataCollectionObj" ? (
                                        <Carousell {...dataCollectionObj} />
                                    ) : productType === "navigationObj" ? (
                                        <Carousell {...navigationObj} />
                                    ) : (
                                        <Carousell {...virtueObj} />
                                    )}
                                </div>
                            </div>

                            <div className="col sol-col">
                                <div className="content-text-wrapper">
                                    <div className="product-content-subtitle product-content-subtitle-marginTop">
                                        <p>{productDescription}</p>
                                        <p>{productLink} </p>
                                    </div>
                                    <div className="product-content-subtitle">
                                        <p
                                            style={{
                                                fontStyle: "italic",
                                                marginTop: "20px",
                                                textDecoration: "underline",
                                                marginBottom: "4px",
                                            }}
                                        >
                                            Features:
                                        </p>
                                        <p>{feature1}</p>
                                        <p>{feature2}</p>
                                        <p>{feature3}</p>
                                        <p><b>{feature4}</b></p>
                                    </div>
                                </div>
                            </div>
                            </div>
                            
                        <div className="row">  
                        {playstoreLink ? (
                            <div className="col-sm-2">
                                <a href={playstoreLink}>
                                    <img
                                        alt="Get it on Google Play"
                                        src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                                        
                                    />
                                </a>
                        </div>
                        ) : (
                            ""
                            )}

                        {appstoreLink ? (
                        <div className="col-sm-2">
                                <a href={appstoreLink}>
                                    <img
                                        src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1659657600&h=b96ee52ec8dd73a456890c68d35488a4"
                                        alt="Download on the App Store"
                                        style={{ margin:"5% 0%", width:"100%", height: "65%" }}
                                    />
                                </a>
                        </div>
                        ) : (
                            ""
                        )}
                        </div>

                        </div>  
                </div>
            </div>
        </>
    );
}

export default SolutionsContentSection;
