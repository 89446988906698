import React from "react";
import "./TextContentSection.css";
import { BiRightArrowAlt } from "react-icons/bi";
import { Link } from "react-router-dom";

function TextContentSection({
    header,
    description,
    productName,
    productLink,
    productDescriptionLong,
    productName2,
    product2Link,
    product2DescriptionLong,
    productName3,
    product3Link,
    product3DescriptionLong,
}) {
    return (
        <>
            <div>
                <div className="text-content-section">
                    <div className="text-container">
                        <div className="text-content-header">{header}</div>
                        <div className="text-content-subheader">
                            {description}
                        </div>
                        <hr style={{ color: "black" }}></hr>

                        <div className="row">
                            <div className="text-col">
                                <div className="text-content-subtitle">
                                    <h2>{productName}</h2>
                                </div>
                            </div>

                            <div className="text-col">
                                <p className="text-content-subtitle text-content-big-subtitle ">
                                    {productDescriptionLong}
                                </p>

                                <Link
                                    to={{
                                        pathname: "/navigation",
                                    }}
                                    style={{
                                        textDecoration: "none",
                                        color: "#696969",
                                    }}
                                >
                                    <span className="text-content-underline text-over-effect">
                                        {productLink} <BiRightArrowAlt />
                                    </span>
                                </Link>
                            </div>

                            <hr style={{ color: "black" }}></hr>
                        </div>

                        <div className="row">
                            <div className="text-col">
                                <div className="text-content-subtitle">
                                    <h2>{productName2}</h2>
                                </div>
                            </div>

                            <div className="text-col">
                                <p className="text-content-subtitle text-content-big-subtitle ">
                                    {product2DescriptionLong}
                                </p>
                                <Link
                                    to={{
                                        pathname: "/dataDashboard",
                                    }}
                                    style={{
                                        textDecoration: "none",
                                        color: "#696969",
                                    }}
                                >
                                    <span className="text-content-underline text-over-effect">
                                        {product2Link} <BiRightArrowAlt />
                                    </span>
                                </Link>
                            </div>

                            <hr style={{ color: "black" }}></hr>
                        </div>

                        <div className="row">
                            <div className="text-col">
                                <div className="text-content-subtitle">
                                    <h2>{productName3}</h2>
                                </div>
                            </div>

                            <div className="text-col">
                                <p className="text-content-subtitle text-content-big-subtitle ">
                                    {product3DescriptionLong}
                                </p>
                                <Link
                                    to={{
                                        pathname: "/dataCollection",
                                    }}
                                    style={{
                                        textDecoration: "none",
                                        color: "#696969"
                                    }}
                                >
                                    <span className="text-content-underline text-over-effect">
                                        {product3Link} <BiRightArrowAlt />
                                    </span>
                                </Link>
                            </div>

                            <hr style={{ color: "black" }}></hr>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default TextContentSection;
