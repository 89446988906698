import React, { useEffect } from "react";
import TextContentSection from "../../components/content/TextContentSection";
import { landingObj } from "./Data";

function Solutions() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <title>SmartBFA</title>
            <TextContentSection {...landingObj}></TextContentSection>
        </>
    );
}

export default Solutions;
