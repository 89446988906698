import React, { useEffect } from 'react';
import { testiObj, landingObj, callToActionObj } from './Data';
import ContentSection from '../../components/content/ContentSection';
import HomePage from './HomePage';

import CardSection from '../../components/content/CardSection';


function Home() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <title>SmartBFA</title>
      <ContentSection {...landingObj}></ContentSection>
      <CardSection {...callToActionObj} />
      <HomePage {...testiObj} />
    </>
  );
}

export default Home;
