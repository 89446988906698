export const ContactUsObj = {
  background: false,
  lightText: false,
  lightTextDesc: false,
  topLine: '',
  header: 'Contact Us',
  description: "Have enquiries about SmartBFA? Have feedback for us on how we can improve our application? We value your inputs! Please email us at smartbfa@gmail.com.",
  button1: false,   
  button2: false,   
  imgStart: '',
  img: 'images/illustration/contactUs.svg',
  alt: 'feedback',
  fullscreen: 'fullscreen'
};